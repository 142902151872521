import { classNames } from "@/utils/util";

export function Progressbar({ percentage, color }: { percentage: number | null; color?: string }) {
    return (
        <div className={classNames("relative w-full rounded-full h-6", percentage === null ? "bg-gray-200/70" : "bg-gray-200")}>
            {percentage !== null && (
                <>
                    <div className={`absolute top-0 left-0 h-full rounded-full ${color || "bg-green-400/80"}`} style={{ width: `${percentage}%` }}></div>
                    <div className="absolute inset-0 flex items-center justify-end px-2 text-xs md:text-sm font-semibold text-gray-800">
                        {percentage.toFixed(1)}%
                    </div>
                </>
            )}
        </div>
    );
}
