import { useState } from "react";

const globalState = new Map<string, any>();

export function useEphemeralGlobalState<T>(key: string, initialValue?: T): [T, (value: T) => void] {
    const [state, setState] = useState<T>(() => {
        if (globalState.has(key)) {
            return globalState.get(key);
        }
        if (initialValue !== undefined) {
            globalState.set(key, initialValue);
        }
        return initialValue;
    });

    const updateState = (value: T) => {
        globalState.set(key, value);
        setState(value);
    };

    return [state, updateState];
}
