import { LoadingView } from "@/views/ErrorView";
import {
    AudiobookSearchOptions,
    AudiobooksSearch,
    getAudioAvailabilityCondition,
    getDownloadedCondition,
    getInterestedCondition,
    getLanguageCondition,
    getStateCondition,
    getTextCondition,
    SearchAudioAvailability,
    SearchDownloaded,
    SearchInterested,
    SearchLanguage,
    SearchState,
} from "./components/AudiobookSearch";
import { Audiobook, AudiobookUserInfo, Author, Series, useGetAudiobooksQuery, useGetSeriesQuery, useGetUsersByIdUserInfoQuery } from "@/api/generatedApi";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import useTranslation from "@/i18n";
import { AudiobookCard } from "./components/AudiobookCard";
import { useEffect, useMemo, useState } from "react";
import { useQueryState } from "@/utils/queryStateHook";
import { useEphemeralGlobalState } from "@/utils/ephemeralState";

type GroupedAudiobooks = {
    author: Author;
    seriesGroups: {
        series: Series | null; // Null if audiobook is not part of a series
        audiobooks: Audiobook[];
    }[];
    books: number;
};

function groupAudiobooksByAuthorAndSeries(filteredAudiobooks: Audiobook[], seriesList: Series[]): GroupedAudiobooks[] {
    // Create a map for quick lookup of series and positions by audiobookId
    const seriesMap = new Map<string, { series: Series; position: number }>();
    for (const series of seriesList) {
        for (const element of series.elements) {
            seriesMap.set(element.audiobookId, { series, position: element.position });
        }
    }

    // Group audiobooks by authors
    const authorGroups = new Map<string, { author: Author; audiobooks: Audiobook[] }>();
    for (const audiobook of filteredAudiobooks) {
        for (const author of audiobook.authors) {
            if (!authorGroups.has(author.id)) {
                authorGroups.set(author.id, { author, audiobooks: [] });
            }
            authorGroups.get(author.id)!.audiobooks.push(audiobook);
        }
    }

    // Convert author groups to array and sort by author name
    const grouped: GroupedAudiobooks[] = Array.from(authorGroups.values())
        .map(({ author, audiobooks }) => {
            // Group audiobooks by series
            const seriesGroupsMap = new Map<string | null, { audiobooks: Audiobook[]; positionMap: Map<string, number> }>();
            for (const audiobook of audiobooks) {
                const seriesInfo = seriesMap.get(audiobook.id);
                const seriesKey = seriesInfo ? seriesInfo.series.id : null;
                if (!seriesGroupsMap.has(seriesKey)) {
                    seriesGroupsMap.set(seriesKey, { audiobooks: [], positionMap: new Map() });
                }
                const group = seriesGroupsMap.get(seriesKey)!;
                group.audiobooks.push(audiobook);
                if (seriesInfo) {
                    group.positionMap.set(audiobook.id, seriesInfo.position);
                }
            }

            // Convert series groups to array and sort by series name and position
            const seriesGroups = Array.from(seriesGroupsMap.entries())
                .map(([seriesKey, { audiobooks, positionMap }]) => ({
                    series: seriesKey ? seriesList.find((s) => s.id === seriesKey) || null : null,
                    audiobooks: audiobooks.sort((a, b) => {
                        const posA = positionMap.get(a.id) ?? 0;
                        const posB = positionMap.get(b.id) ?? 0;
                        return posA - posB; // Sort by position
                    }),
                }))
                .sort((a, b) => (a.series?.name || "").localeCompare(b.series?.name || ""));

            return { author, seriesGroups, books: audiobooks.length };
        })
        .sort((a, b) => a.author.name.localeCompare(b.author.name));

    return grouped;
}

function LoadedAudiobookList({
    audiobooks,
    audiobookUserInfos,
    seriesList,
}: {
    audiobooks: Audiobook[];
    audiobookUserInfos: AudiobookUserInfo[];
    seriesList: Series[];
}) {
    const t = useTranslation();

    // URL search params
    const [searchText, setSearchText] = useQueryState<string>("searchText", "");
    const [searchState, setSearchState] = useQueryState<SearchState>("searchState", SearchState.ANY);
    const [searchInterested, setSearchInterested] = useQueryState<SearchInterested>("searchInterested", SearchInterested.ANY);
    const [searchLanguage, setSearchLanguage] = useQueryState<SearchLanguage>("searchLanguage", SearchLanguage.ANY);
    const [searchAudioAvailability, setSearchAudioAvailability] = useQueryState<SearchAudioAvailability>(
        "searchAudioAvailability",
        SearchAudioAvailability.ANY
    );
    const [searchDownloaded, setSearchDownloaded] = useQueryState<SearchDownloaded>("searchDownloaded", SearchDownloaded.ANY);

    // ephemeral state based on URL search params to assure the second visit of these components keeps the state
    const [searchOpts, setSearchOpts] = useEphemeralGlobalState<AudiobookSearchOptions>("audiobooks-search", {
        text: searchText,
        state: searchState,
        interested: searchInterested,
        language: searchLanguage,
        audioAvailability: searchAudioAvailability,
        downloaded: searchDownloaded,
    });
    useEffect(() => {
        setSearchText(searchOpts.text);
        setSearchState(searchOpts.state);
        setSearchInterested(searchOpts.interested);
        setSearchLanguage(searchOpts.language);
        setSearchAudioAvailability(searchOpts.audioAvailability);
        setSearchDownloaded(searchOpts.downloaded);
    }, [searchOpts]);

    const userInfoMap = useMemo(() => {
        const userInfoMap = new Map<string, AudiobookUserInfo>();
        audiobookUserInfos.forEach((userInfo) => userInfoMap.set(userInfo.audiobookId, userInfo));
        return userInfoMap;
    }, [audiobookUserInfos]);

    const [filteredAudiobooks, setFilteredAudiobooks] = useState(audiobooks);
    useEffect(() => {
        (async function () {
            const filteredAudioBooks: Audiobook[] = [];
            for (const audiobook of audiobooks) {
                if (
                    getTextCondition(searchOpts.text, audiobook) &&
                    getStateCondition(searchOpts.state, audiobook, userInfoMap.get(audiobook.id)) &&
                    getInterestedCondition(searchOpts.interested, audiobook, userInfoMap.get(audiobook.id)) &&
                    getLanguageCondition(searchOpts.language, audiobook) &&
                    getAudioAvailabilityCondition(searchOpts.audioAvailability, audiobook) &&
                    (await getDownloadedCondition(searchOpts.downloaded, audiobook))
                ) {
                    filteredAudioBooks.push(audiobook);
                }
            }
            setFilteredAudiobooks(filteredAudioBooks);
        })();
    }, [audiobooks, userInfoMap, searchOpts]);

    const audiobooksGrouped = useMemo(() => groupAudiobooksByAuthorAndSeries(filteredAudiobooks, seriesList), [filteredAudiobooks]);

    return (
        <div>
            <div className="m-3 flex flex-col justify-center items-center gap-3">
                <h1 className="text-2xl leading-tight">
                    {filteredAudiobooks.length} {t("audiobooks")}
                </h1>

                <AudiobooksSearch searchOpt={searchOpts} setSearch={setSearchOpts} />

                <GroupedList audiobooksGrouped={audiobooksGrouped} audiobookUserInfos={audiobookUserInfos} />
            </div>
        </div>
    );
}

function GroupedList({ audiobooksGrouped, audiobookUserInfos }: { audiobooksGrouped: GroupedAudiobooks[]; audiobookUserInfos: AudiobookUserInfo[] }) {
    const groupedBooks = useMemo(() => {
        const books: Audiobook[] = [];
        for (const author of audiobooksGrouped) {
            for (const series of author.seriesGroups) {
                for (const book of series.audiobooks) {
                    books.push(book);
                }
            }
        }
        // deduplicate
        return books.filter((book, index, self) => self.findIndex((b) => b.id === book.id) === index);
    }, [audiobooksGrouped]);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
            {groupedBooks.map((audiobook) => {
                const info = audiobookUserInfos.find((it) => it.audiobookId === audiobook.id);
                return <AudiobookCard key={audiobook.id} audiobook={audiobook} info={info} />;
            })}
        </div>
    );
}

function SectionGroupedList({ audiobooksGrouped, audiobookUserInfos }: { audiobooksGrouped: GroupedAudiobooks[]; audiobookUserInfos: AudiobookUserInfo[] }) {
    const authorsWithMultipleBooks = useMemo(() => audiobooksGrouped.filter((group) => group.books > 1), [audiobooksGrouped]);
    const standAloneBooks = useMemo(() => {
        const authorsWithSingleBooks = audiobooksGrouped.filter((group) => group.books === 1);
        const books = authorsWithSingleBooks
            .map((group) => group.seriesGroups.map((series) => series.audiobooks))
            .flat()
            .flat();

        // we have to deduplicate since a book with multiple authors will be listed multiple times
        const deduplicated = books.filter((book, index, self) => self.findIndex((b) => b.id === book.id) === index);
        return deduplicated;
    }, [audiobooksGrouped]);

    return (
        <div className="flex flex-col gap-4">
            {authorsWithMultipleBooks.map((audiobooksGroup) => {
                const multiPartSeriesList = audiobooksGroup.seriesGroups.filter((series) => series.audiobooks.length > 1);
                const singlePartSeriesList = audiobooksGroup.seriesGroups.filter((series) => series.audiobooks.length === 1);

                return (
                    <div key={audiobooksGroup.author.id}>
                        <h3 className="">{audiobooksGroup.author.name}</h3>
                        <div className="flex flex-col gap-3">
                            {multiPartSeriesList.map((series) => (
                                <div key={series.series?.id || "null"}>
                                    {/* <h4>{series.series?.name}</h4> */}
                                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                                        {series.audiobooks.map((audiobook) => {
                                            const info = audiobookUserInfos.find((it) => it.audiobookId === audiobook.id);
                                            return <AudiobookCard key={`${audiobooksGroup.author.id}-${audiobook.id}`} audiobook={audiobook} info={info} />;
                                        })}
                                    </div>
                                </div>
                            ))}
                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                                {singlePartSeriesList
                                    .map((s) => s.audiobooks)
                                    .flat()
                                    .map((audiobook) => {
                                        const info = audiobookUserInfos.find((it) => it.audiobookId === audiobook.id);
                                        return <AudiobookCard key={`${audiobooksGroup.author.id}-${audiobook.id}`} audiobook={audiobook} info={info} />;
                                    })}
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                {standAloneBooks.map((audioBook) => {
                    const info = audiobookUserInfos.find((it) => it.audiobookId === audioBook.id);
                    return <AudiobookCard key={audioBook.id} audiobook={audioBook} info={info} />;
                })}
            </div>
        </div>
    );
}

export default function AudiobookList() {
    const loggedInUser = useSelector(selectUser)!;
    const { data: audiobooks, error: audiobookError } = useGetAudiobooksQuery();
    const { data: audiobookUserInfos, error: infoError } = useGetUsersByIdUserInfoQuery({ id: loggedInUser.id });
    const { data: series, error: seriesError } = useGetSeriesQuery();

    const err = audiobookError || infoError || seriesError;
    if (err) {
        return <ErrorComponent error={err} />;
    }

    if (audiobooks === undefined || audiobookUserInfos === undefined || series === undefined) {
        return <LoadingView description={""} />;
    }

    return <LoadedAudiobookList audiobooks={audiobooks} audiobookUserInfos={audiobookUserInfos} seriesList={series} />;
}
