export function SleepTimerIcon({ minutes, className, onClick }: { minutes?: number } & { className?: string; onClick?: () => void }) {
    return (
        <div className="relative inline-block">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                onClick={onClick}
                className={className ?? "size-6"}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>

            {/* The number badge */}
            {minutes !== undefined && (
                <span
                    style={{
                        position: "absolute",
                        bottom: "-7px",
                        right: "-18px",
                        color: "inherit",
                        fontSize: "10px",
                        fontWeight: "bold",
                        borderRadius: "50%",
                        padding: "2px 4px",
                        minWidth: "16px",
                        textAlign: "center",
                        lineHeight: "1",
                    }}
                >
                    {minutes.toFixed(0)}m
                </span>
            )}
        </div>
    );
}
