import { Translation } from "./index"

export type HistoryTranslationKey =
    | "history"
    | "historyThisMonth"
    | "historyThisYear"
    | "historyBook"
    | "historyBooks"

export const HISTORY_TRANSLATION: Translation<HistoryTranslationKey> = {
    english: {
        history: "history",
        historyThisMonth: "This Month",
        historyThisYear: "This Year",
        historyBook: "book",
        historyBooks: "books",
    },
    german: {
        history: "Verlauf",
        historyThisMonth: "Dieser Monat",
        historyThisYear: "Dieses Jahr",
        historyBook: "Buch",
        historyBooks: "Bücher",
    }
}
