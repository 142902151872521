import { useEffect, useState } from "react";

export function useWakeLock(loading: boolean) {
    const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

    useEffect(() => {
        const requestWakeLock = async () => {
            if (!("wakeLock" in navigator)) {
                console.warn("Wake Lock API not supported in this browser.");
                return;
            }

            try {
                const lock = await navigator.wakeLock.request("screen");
                setWakeLock(lock);
                console.log("Wake lock acquired");

                // Automatically release the wake lock if it is lost
                lock.addEventListener("release", () => {
                    console.log("Wake lock released");
                    setWakeLock(null);
                });
            } catch (err) {
                console.error("Failed to acquire wake lock:", err);
            }
        };

        const releaseWakeLock = () => {
            if (wakeLock) {
                wakeLock.release();
                setWakeLock(null);
                console.log("Wake lock manually released");
            }
        };

        if (loading) {
            requestWakeLock();
        } else {
            releaseWakeLock();
        }

        return () => {
            releaseWakeLock();
        };
    }, [loading, wakeLock]);
}
