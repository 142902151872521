import { Audiobook, AudiobookUserInfo, useLazyGetAudiobooksByIdUserInfoQuery, usePutAudiobooksByIdUserInfoMutation } from "@/api/generatedApi";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useImageHook } from "@/utils/imageHook";
import { setErrorNotification, setSuccessNotification } from "@/store/notificationSlice";
import { getErrorMessage } from "@/components/util/ErrorComponent";
import { isFinished } from "@/utils/audiobookUtils";
import { useNavigate } from "react-router-dom";
import { formatDuration, formatString } from "@/utils/util";
import ic_check from "@/img/checkmark.png";
import ic_cross from "@/img/cross.png";
import { Card } from "@/components/Card";
import { CopyableParagraph } from "@/components/CopyParagraph";

export function AudiobookCard(props: { audiobook: Audiobook; info: AudiobookUserInfo | undefined }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { audiobook, info } = props;
    const [userInfo, setUserInfo] = useState(info);

    const [fetchInfo, { data: newUserInfo }] = useLazyGetAudiobooksByIdUserInfoQuery();
    const [updateInfo, { data: updateUserInfoData, error: updateUserInfoError }] = usePutAudiobooksByIdUserInfoMutation();
    const imgSrc = useImageHook(audiobook.id, 128);

    useEffect(() => {
        if (updateUserInfoError === undefined) {
            return;
        }
        dispatch(setErrorNotification({ text: "Toggle Failed" }));
        getErrorMessage(updateUserInfoError);
    }, [updateUserInfoError]);

    useEffect(() => {
        if (updateUserInfoData === undefined) {
            return;
        }
        dispatch(setSuccessNotification({ text: `Toggle ${audiobook.name} Finished` }));
        fetchInfo({ id: audiobook.id });
    }, [updateUserInfoData]);

    useEffect(() => {
        if (newUserInfo === undefined) {
            return;
        }
        setUserInfo(newUserInfo);
    }, [newUserInfo]);

    const audiobookFinished = userInfo ? isFinished(audiobook, userInfo) : false;
    const authorsText = audiobook.authors.map((author) => author.name).join(", ");
    const narratorsText = audiobook.narrators.map((narrator) => narrator.name).join(", ");

    return (
        <Card className={`flex justify-items-start hover:ring-gray-400 hover:ring-2 cursor-pointer`} onClick={() => navigate(`/audiobooks/${audiobook.id}`)}>
            <div className="shrink-0">
                <img className="h-36 w-36 rounded-l" src={imgSrc} alt="Icon" />
            </div>

            <div className="ml-5 py-2 min-w-0">
                {formatString(audiobook.name, 40)}
                <CopyableParagraph className="truncate">{formatString(authorsText, 25)}</CopyableParagraph>
                <CopyableParagraph className="truncate italic">{formatString(narratorsText, 25)}</CopyableParagraph>
                <p className="truncate italic">{formatDuration(audiobook.lengthMsec)}</p>
            </div>

            <div className="flex shrink-0 mt-2 mx-2 grow justify-end">
                <img
                    src={audiobookFinished ? ic_check : ic_cross}
                    className="h-6 w-6 hover:cursor-pointer"
                    alt="Finished"
                    onClick={(e) => {
                        e.stopPropagation();

                        if (audiobookFinished) {
                            updateInfo({
                                id: audiobook.id,
                                updateAudiobookUserInfo: {
                                    progressMSec: 0,
                                    lastListenedTo: new Date().toISOString(),
                                },
                            });
                        } else {
                            updateInfo({
                                id: audiobook.id,
                                updateAudiobookUserInfo: {
                                    progressMSec: audiobook.lengthMsec,
                                    lastListenedTo: new Date().toISOString(),
                                },
                            });
                        }
                    }}
                />
            </div>
        </Card>
    );
}
