import useTranslation from "@/i18n";
import { NewSeriesComponent } from "@/views/series/components/NewSeriesComponent";
import { NewBooksComponent } from "@/views/audiobook/components/NewBooksComponent";
import { WishlistComponent } from "./WishlistComponent";

export default function HomeView() {
    const t = useTranslation();

    return (
        <div className="flex-grow xl:max-w-7xl xl:mx-auto">
            <div className="mx-auto max-w-7xl px-6 pt-5 pb-8 lg:px-8">
                <div className="text-center">
                    <p className="mx-auto mt-5 max-w-xl text-xl text-gray-400 dark:text-gray-300">{t("homeViewWelcomeText")}</p>
                </div>
            </div>
            <NewBooksComponent />
            <NewSeriesComponent />
            {/* FixMe <StatComponent/>*/}
            <WishlistComponent />
        </div>
    );
}
