import useTranslation from "@/i18n";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { LoadingView } from "../ErrorView";
import { formatDuration } from "@/utils/util";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { isFinished } from "@/utils/audiobookUtils";
import { useGetAudiobooksQuery, useGetUsersByIdUserInfoQuery, useLazyGetSeriesByIdQuery } from "@/api/generatedApi";
import { setErrorNotification } from "@/store/notificationSlice";
import { Card } from "@/components/Card";
import { SeriesBookCard } from "@/views/series/components/SeriesBookCard";


export default function SeriesDetailsView() {
    const t = useTranslation()
    const dispatch = useDispatch();

    const { seriesId } = useParams()
    const loggedInUser = useSelector(selectUser)!!


    const [fetchSeriesSingle, { data: series, error: seriesError }] = useLazyGetSeriesByIdQuery()

    const { data: audiobooks, error: audiobookError } = useGetAudiobooksQuery()
    const { data: audiobookUserInfos, error: infoError } = useGetUsersByIdUserInfoQuery({ id: loggedInUser.id })

    useEffect(() => {
        if (seriesId === undefined) return

        fetchSeriesSingle({ id: seriesId })
    }, [seriesId])

    useEffect(() => {
        if (audiobookError !== undefined) {
            dispatch(setErrorNotification({ text: "Loading Audiobooks failed" }));
        }
    }, [audiobookError]);

    useEffect(() => {
        if (infoError !== undefined) {
            dispatch(setErrorNotification({ text: "Loading User Info failed" }));
        }
    }, [infoError]);

    if (seriesError) return <ErrorComponent error={seriesError} />
    if (!series || !audiobooks || !audiobookUserInfos) return <LoadingView description={"loading"} />

    const data = []
    for (const seriesElement of series.elements) {
        const audiobook = audiobooks.find(a => a.id == seriesElement.audiobookId)
        if (!audiobook) continue
        data.push({
            "seriesElement": seriesElement,
            "audiobook": audiobook,
            "userInfo": audiobookUserInfos.find(aui => aui.audiobookId == audiobook.id),
        })
    }
    let dataSorted = [...data].sort((b, a) => a.seriesElement.position > b.seriesElement.position ? -1 : 1)


    const totalSeriesDuration = data.map((ele) => ele.audiobook.lengthMsec).reduce((a, b) => a + b, 0)
    const booksFinishedCount = data.filter(ele => {
        return ele.userInfo && ele.audiobook && isFinished(ele.audiobook, ele.userInfo)
    }).length
    const progress = Math.round(booksFinishedCount / data.length * 100 * 100) / 100

    return (
        <div className="max-w-5xl mx-auto m-3 px-3">
            <div className="flex justify-center">
                <h1 className="text-3xl mb-5">{series.name}</h1>
            </div>

            <div className="grid sm:grid-cols-3 gap-3 sm:gap-6 mb-3">
                <Card className={`flex flex-col p-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("seriesTotalDuration")}</div>
                    <div className="order-1 text-3xl font-bold">
                        {formatDuration(totalSeriesDuration)}
                    </div>
                </Card>
                <Card className={`flex flex-col p-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("seriesTitleCount")}</div>
                    <div className="order-1 text-3xl font-bold">{data.length}</div>
                </Card>
                <Card className={`flex flex-col p-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("seriesProgress")}</div>
                    <div className="order-1 text-3xl font-bold">{progress}%</div>
                </Card>
            </div>

            <div className="grid grid-cols-8">
                {dataSorted.map((ele) => {
                    return (<>
                        <div className="col-span-1 m-auto sm:text-3xl text-2xl">
                            <div className="m-0">
                                #{ele.seriesElement.position}
                            </div>
                        </div>
                        <div className="col-span-7">
                            <SeriesBookCard key={ele.audiobook.id} audiobook={ele.audiobook} info={ele.userInfo} />
                        </div>
                    </>)
                })}
            </div>
        </div>
    )
}
