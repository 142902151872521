import { useDeleteWishlistByIdMutation, useGetWishlistQuery, usePostWishlistMutation } from "@/api/generatedApi";
import { ErrorComponent, getErrorMessage } from "@/components/util/ErrorComponent";
import React, { useEffect, useState } from "react";
import useTranslation from "@/i18n";
import { setErrorNotification, setSuccessNotification } from "@/store/notificationSlice";
import { useDispatch } from "react-redux";
import { inputCls } from "@/styles";
import { TagComponent } from "@/components/ui/TagComponent";
import { InputComponent } from "@/components/ui/InputComponent";
import { formatDate } from "@/utils/util";
import { ButtonComponent } from "@/components/ui/ButtonComponent";
import { LoadingView } from "../ErrorView";
import {DeleteIcon} from "@/components/icons/DeleteIcon";

function NewWishlistElementForm(props: { successCallback: () => void }) {
    const t = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [releaseDate, setReleaseDate] = useState<string>("");

    const [postWishlistElement, { data, error }] = usePostWishlistMutation();

    useEffect(() => {
        if (error) {
            dispatch(setErrorNotification({ text: t(getErrorMessage(error)) }));
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            dispatch(setSuccessNotification({ text: t("saved") }));
        }
    }, [data]);

    return (
        <div className="mx-auto max-w-2xl px-2 lg:px-8">
            <h2 className="font-bold text-xl">{t("addEntry")}</h2>
            <label className="block text-sm font-bold mb-2" htmlFor="config">
                {t("wishlistDescription")}
            </label>
            <textarea
                className={inputCls}
                id="name-input"
                placeholder={t("wishlistWhatsMissing")}
                value={name}
                onChange={(event) => setName(event.target.value)}
            />
            <InputComponent
                value={releaseDate}
                id="releaseDate-input"
                label={t("wishlistReleaseDateInput")}
                onChange={(value) => setReleaseDate(value)}
                type="date"
            />

            <ButtonComponent
                text={t("wishlistAdd")}
                onClick={() => {
                    releaseDate ? postWishlistElement({ body: { name: name, releaseDate: releaseDate } }) : postWishlistElement({ body: { name: name } });
                }}
                className="ml-0 my-3"
            />
        </div>
    );
}

function WishlistElement(props: { id: string; name: string; createdBy: string; releaseDate: string | undefined }) {
    const { id, name, createdBy, releaseDate } = props;

    const t = useTranslation();
    const dispatch = useDispatch();

    const [deleteWishlistElement, { data: deleteData, error: deleteError }] = useDeleteWishlistByIdMutation();

    useEffect(() => {
        if (deleteError) {
            dispatch(setErrorNotification({ text: t(getErrorMessage(deleteError)) }));
        }
    }, [deleteError]);
    useEffect(() => {
        if (deleteData) {
            dispatch(setSuccessNotification({ text: t("deleted") }));
        }
    }, [deleteData]);

    return (
        <div className="bg-white dark:bg-slate-700 rounded p-2 shadow-lg hover:ring-gray-400 hover:ring-2">
            <div className="flex justify-between gap-2 md:gap-3">
                <div>{name}</div>
                <ButtonComponent
                    text={<DeleteIcon className="size-5" />}
                    onClick={(e) => {
                        deleteWishlistElement({ id: id });
                    }}
                    className="m-0 mr-1"
                />
            </div>

            <div>
                <TagComponent name={createdBy} />
                {releaseDate && (
                    <TagComponent
                        className={new Date().getTime() > new Date(releaseDate).getTime() ? "bg-lime-200" : "bg-rose-200"}
                        name={`${t("wishlistReleasedOn")}: ${formatDate(new Date(releaseDate), false)}`}
                    />
                )}
            </div>
        </div>
    );
}

export function WishlistComponent() {
    const t = useTranslation();
    const { data: wishlist, error, refetch } = useGetWishlistQuery();

    const [addToWishlistOpen, setAddToWishlistOpen] = useState(false);

    if (error) {
        console.error(error);
        return <ErrorComponent error={error} />;
    }
    if (wishlist === undefined) {
        return <LoadingView description={""} />;
    }

    return (
        <div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">{t("wishlist")}</h2>
                </div>
            </div>

            <div className={`container m-auto grid grid-cols-1 gap-2 md:flex md:gap-3 mt-3 flex-wrap`}>
                {wishlist.map((it) => (
                    <WishlistElement key={it.id} id={it.id} name={it.name} createdBy={it.createdBy} releaseDate={it.releaseDate} />
                ))}
            </div>

            {addToWishlistOpen && <NewWishlistElementForm successCallback={refetch} />}

            <div className="grid place-content-center my-3">
                <ButtonComponent text={addToWishlistOpen ? "-" : "+"} onClick={(e) => setAddToWishlistOpen(!addToWishlistOpen)} className="w-16" />
            </div>
        </div>
    );
}
