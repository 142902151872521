import React from "react";

export function LinkComponent(props: {
    text: string,
    disabled?: boolean
    href: string
    className?: string
    newTab?: boolean
}) {
    return (
        <a
            className={`text-gray-600 dark:text-white shadow-sm dark:shadow-gray-600 py-2 px-2 m-1 rounded transition
                       dark:bg-slate-700 bg-white
                       border border-gray-300 dark:border-gray-500 
                       ${props.disabled ? "bg-slate-200 dark:bg-gray-800 cursor-not-allowed" : "hover:bg-gray-200 dark:hover:bg-gray-600"}  
                       ${props.className}`}
            href={props.href} target={props.newTab ? "_blank" : ""}
        >
            {props.text}
        </a>
    )
}