
export function zeroPad2(n: number | string): string {
    const nStr = n.toString();
    if (nStr.length === 1) {
        return "0" + nStr;
    }
    return nStr;
}


export function formatDuration(timeMillis: number, includeMinutes?: boolean): string {
    if (includeMinutes === undefined) includeMinutes = true;

    if (timeMillis <= 0) {
        return "0h 0min";
    }

    const timeSeconds = timeMillis / 1000;
    const minutesNum = Math.floor((timeSeconds / 60.0) % 60);
    const hoursNum = Math.floor((timeSeconds / (60.0 * 60.0)) % 24);
    const daysNum = Math.floor((timeSeconds / (60.0 * 60.0 * 24.0)));

    let minutes = "";
    if (minutesNum !== 0) {
        let m = minutesNum.toFixed(0);
        if (hoursNum > 0) {
            m = zeroPad2(m);
        }
        minutes = m + "min";
    }

    let hours = "";
    if (hoursNum !== 0) {
        let h = hoursNum.toFixed(0);
        if (daysNum > 0) {
            h = zeroPad2(h);
        }
        hours = h + "h ";
    }

    let days = "";
    if (daysNum !== 0) {
        let d = daysNum.toFixed(0);
        days = d + "d ";
    }

    return `${days}${hours}${includeMinutes ? minutes : ""}`;
}

export function formatDate(date: Date, includeTime?: boolean): string {
    if (includeTime === undefined) includeTime = true;

    const dateStr = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
    const timeStr = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    if (includeTime) {
        return `${dateStr} ${timeStr}`;
    } else {
        return dateStr;
    }
}

export function formatDateWeeksAgo(date: Date): number {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
}

export function formatString(str: string, maxLength: number): string {
    return str.length < maxLength ? str : str.substring(0, maxLength - 3) + "...";
}

export function sum(...vals :number[]): number {
    return vals.reduce((sum, num) => sum + num, 0);
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}
