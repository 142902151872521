import useTranslation from "@/i18n";
import { setNotification } from "@/store/notificationSlice";
import { Second } from "@/utils/constant";
import { ReactNode, useRef } from "react";
import { useDispatch } from "react-redux";

export function CopyableParagraph({ className, children }: { className?: string, children: ReactNode }) {
    const t = useTranslation()
    const dispatch = useDispatch();
    const paragraphRef = useRef<HTMLParagraphElement>(null);

    const copyToClipboard = async (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.stopPropagation();
        if (paragraphRef.current) {
            const text = paragraphRef.current.innerText;
            await navigator.clipboard.writeText(text)
            dispatch(setNotification({
                title: t("copied"),
                text: t("the text has been copied to your clipboard"),
                type: "info",
                duration: 4 * Second
            }))
        }
    };

    return (
        <p
            ref={paragraphRef}
            className={`cursor-copy-svg hover:underline ${className || ''}`}
            onClick={copyToClipboard}
        >
            {children}
        </p>
    );
}
