import { Translation } from "./index";

export type SearchTranslationKey =
  | "searchAny"
  | "searchIgnore"
  | "searchInterested"
  | "searchNew"
  | "searchFinished"
  | "searchAudioAvailable"
  | "searchAudioUnavailable"
  | "downloaded"
  | "not downloaded"
  | "download any";

export const SEARCH_TRANSLATION: Translation<SearchTranslationKey> = {
  english: {
    searchAny: "Any",
    searchIgnore: "Ignore",
    searchInterested: "Interested",
    searchNew: "New",
    searchFinished: "Finished",
    searchAudioAvailable: "Audio available",
    searchAudioUnavailable: "Audio unavailable",
    downloaded: "Downloaded",
    "not downloaded": "Not Downloaded",
    "download any": "All",
  },
  german: {
    searchAny: "Alle",
    searchIgnore: "Ignoriert",
    searchInterested: "Interessant",
    searchNew: "Neu",
    searchFinished: "Beendet",
    searchAudioAvailable: "Audio verfügbar",
    searchAudioUnavailable: "Audio nicht verfügbar",
    downloaded: "Heruntergeladen",
    "not downloaded": "Nicht heruntergeladen",
    "download any": "Alle",
  },
};
