import useTranslation from "@/i18n";
import { Series, useGetSeriesQuery } from "@/api/generatedApi";
import { LoadingView } from "@/views/ErrorView";
import { useImageHook } from "@/utils/imageHook";
import { formatDateWeeksAgo } from "@/utils/util";
import { Link } from "react-router-dom";
import { ErrorComponent } from "@/components/util/ErrorComponent";

function NewSeriesCard(props: { series: Series }) {
    const t = useTranslation();
    const { series } = props;

    let thumbnailAudiobookId = "00000000-0000-0000-0000-000000000000";
    if (series.elements.length > 0) {
        thumbnailAudiobookId = series.elements[0].audiobookId;
    }
    const imgSrc = useImageHook(thumbnailAudiobookId, 512);

    const weeks = formatDateWeeksAgo(new Date(Date.parse(series.timeCreated)));
    return (
        <div className="w-64 rounded overflow-hidden bg-white dark:bg-slate-700 shadow-lg hover:ring-gray-400 hover:ring-2">
            <Link className="" to={`/series/${series.id}`}>
                <div className="h-64">
                    <img className="object-fill w-full" src={imgSrc} alt="Cover" />
                </div>
                <div className="px-2 py-2">
                    <div className="font-bold text-xl mb-1">{series.name}</div>
                    <p>
                        {series.elements.length} {series.elements.length > 1 ? t("audiobooks") : t("audiobook")}
                    </p>
                    <p className="text-xs px-3 py-1 mt-2 rounded-full bg-gray-200 dark:bg-slate-500 text-gray-700 dark:text-white inline-block">
                        {weeks} {weeks != 1 ? t("weeksAgo") : t("weekAgo")}
                    </p>
                </div>
            </Link>
        </div>
    );
}

export function NewSeriesComponent() {
    const t = useTranslation();

    const { data: series, error: seriesError } = useGetSeriesQuery();

    if (seriesError) return <ErrorComponent error={seriesError} />;

    if (!series) return <LoadingView description={t("loading")} />;
    if (series.length == 0) return <div>Library is currently Empty.</div>;

    let seriesSorted = [...series].sort((b, a) => Date.parse(a.timeCreated) - Date.parse(b.timeCreated));
    seriesSorted = seriesSorted.slice(0, Math.min(20, seriesSorted.length));

    return (
        <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">{t("recentlyAddedSeries")}</h2>
            <div className="flex overflow-x-scroll scrollbar-hide">
                <div className="flex flex-nowrap mt-3 mb-6 mx-3 gap-5">
                    {seriesSorted.map((series: Series) => {
                        return <NewSeriesCard key={series.id} series={series} />;
                    })}
                </div>
            </div>
        </div>
    );
}
