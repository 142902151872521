import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useQueryState<T>(
    key: string,
    initialValue: T,
    serializer: (value: T) => string = JSON.stringify,
    deserializer: (value: string) => T = JSON.parse
): [T, (value: T) => void] {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialQueryValue = searchParams.get(key);

    const [state, setState] = useState<T>(initialQueryValue !== null ? deserializer(initialQueryValue) : initialValue);

    useEffect(() => {
        if (state === initialValue) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, serializer(state));
        }
        setSearchParams(searchParams);
    }, [state, key, serializer, initialValue, searchParams, setSearchParams]);

    const setQueryState = useCallback(setState, []);

    return [state, setQueryState];
}
