import { Resolution } from "../api/generatedApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/userSlice";
import ic_placeholder from "../img/placeholder.png";
import { KeyValueStore } from "./kvStore";

export function getImageKey(audiobookId: string, resolution: Resolution) {
    return `z_${audiobookId}_${resolution.toString()}`;
}

const kvStore = new KeyValueStore("images");

export function useImageHook(audiobookId: string | undefined, resolution: Resolution) {
    const [imageSrc, setImgSrc] = useState<string>(ic_placeholder);
    const loggedInUser = useSelector(selectUser);

    useEffect(() => {
        if (!loggedInUser || !audiobookId) return;

        (async () => {
            try {
                const imageKey = getImageKey(audiobookId, resolution);

                const cachedImage = await kvStore.get<string>(imageKey);
                if (cachedImage) {
                    setImgSrc(cachedImage);
                    return;
                }

                // Fetch the image if not in cache
                const src = `${import.meta.env.VITE_API_URL}audiobooks/${audiobookId}/thumbnail/${resolution}`;
                const options = {
                    headers: { Authorization: `Bearer ${loggedInUser.sessionToken}` },
                };

                const response = await fetch(src, options);
                if (response.status === 404) {
                    throw new Error(`Not found! (${response.url})`);
                }

                const blob = await response.blob();

                // Convert blob to a data URL
                const reader = new FileReader();
                reader.onload = async () => {
                    const dataUrl = reader.result as string;
                    await kvStore.set(imageKey, dataUrl);
                    setImgSrc(dataUrl);
                };
                reader.readAsDataURL(blob);
            } catch (err) {
                console.error("Failed to load or fetch image:", err);
            }
        })();
    }, [audiobookId, resolution, loggedInUser]);

    return imageSrc;
}
