import useTranslation from "@/i18n";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { useGetAudiobooksQuery, useGetUsersByIdUserInfoQuery } from "@/api/generatedApi";
import React, { useEffect } from "react";
import { setErrorNotification } from "@/store/notificationSlice";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { LoadingView } from "@/views/ErrorView";
import { Card } from "@/components/Card";
import { formatDate, formatDuration } from "@/utils/util";
import { HistoryBookCard } from "@/views/history/components/HistoryBookCard";

export default function History() {
    const t = useTranslation();

    const dispatch = useDispatch();

    const loggedInUser = useSelector(selectUser)!!;

    const { data: audiobooks, error: audiobookError } = useGetAudiobooksQuery();
    const { data: audiobookUserInfos, error: infoError } = useGetUsersByIdUserInfoQuery({ id: loggedInUser.id });

    useEffect(() => {
        if (audiobookError !== undefined) {
            dispatch(setErrorNotification({ text: "Loading Audiobooks failed" }));
        }
    }, [audiobookError]);

    useEffect(() => {
        if (infoError !== undefined) {
            dispatch(setErrorNotification({ text: "Loading User Info failed" }));
        }
    }, [infoError]);

    if (audiobookError) return <ErrorComponent error={audiobookError} />;
    if (!audiobooks || !audiobookUserInfos) return <LoadingView description={"loading"} />;

    const audiobookMap = new Map(audiobooks.map((a) => [a.id, a]));

    const audiobooksWithUserInfo = [];
    for (const userInfo of audiobookUserInfos) {
        if (!userInfo || !userInfo.lastListenedTo) continue;
        const audiobook = audiobookMap.get(userInfo.audiobookId);
        if (!audiobook || audiobook.lengthMsec > userInfo.progressMSec) continue;

        audiobooksWithUserInfo.push({
            audiobook: audiobook,
            userInfo: userInfo,
        });
    }
    const audiobooksWithUserInfoSorted = [...audiobooksWithUserInfo].sort((b, a) => (a.userInfo.lastListenedTo!! < b.userInfo.lastListenedTo!! ? -1 : 1));

    const booksFinishedThisMonth = audiobooksWithUserInfoSorted.filter((ele) => {
        const date = new Date(ele.userInfo.lastListenedTo!!);
        return date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();
    });
    const totalMillisListenedThisMonth = booksFinishedThisMonth.reduce((acc, ele) => acc + ele.audiobook.lengthMsec, 0);
    const totalBooksFinishedThisMonth = booksFinishedThisMonth.length;

    const booksFinishedThisYear = audiobooksWithUserInfoSorted.filter((ele) => {
        const date = new Date(ele.userInfo.lastListenedTo!!);
        return date.getFullYear() === new Date().getFullYear();
    });
    const totalMillisListenedThisYear = booksFinishedThisYear.reduce((acc, ele) => acc + ele.audiobook.lengthMsec, 0);
    const totalBooksFinishedThisYear = booksFinishedThisYear.length;

    return (
        <div className="max-w-5xl mx-auto m-3 px-1.5">
            <div className="flex justify-center">
                <h1 className="text-3xl mb-5">{t("history")}</h1>
            </div>

            <div className="sm:grid sm:grid-cols-2 gap-6 mb-3">
                <Card className={`flex flex-col p-3 mb-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("historyThisMonth")}</div>
                    <div className="order-1 text-3xl font-bold">
                        {formatDuration(totalMillisListenedThisMonth)} / {totalBooksFinishedThisMonth}{" "}
                        {totalBooksFinishedThisMonth == 1 ? t("historyBook") : t("historyBooks")}
                    </div>
                </Card>
                <Card className={`flex flex-col p-3 mb-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("historyThisYear")}</div>
                    <div className="order-1 text-3xl font-bold">
                        {formatDuration(totalMillisListenedThisYear, false)} / {totalBooksFinishedThisYear}{" "}
                        {totalBooksFinishedThisYear == 1 ? t("historyBook") : t("historyBooks")}
                    </div>
                </Card>
            </div>

            <div className="flex flex-col">
                {audiobooksWithUserInfoSorted.map((ele) => {
                    return (
                        <div key={ele.audiobook.id} className="grid grid-cols-5">
                            <div className="m-auto sm:text-2xl text-lg">
                                <div className="m-0">{formatDate(new Date(ele.userInfo.lastListenedTo!!), false)}</div>
                            </div>
                            <div className="col-span-4">
                                <HistoryBookCard key={ele.audiobook.id} audiobook={ele.audiobook} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
