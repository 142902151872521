import { useGetVersionQuery } from "@/api/generatedApi";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { formatDate } from "@/utils/util";
import {GitHubIcon} from "@/components/icons/GitHubIcon";

export default function FooterComponent() {
    const { data, error } = useGetVersionQuery()

    if (error) return <ErrorComponent error={error} />

    return <footer className="h-20 border-t border-gray-900 dark:border-gray-200">
        <div className="mx-auto max-w-7xl h-full px-6 lg:px-8 flex items-center justify-between text-gray-500 dark:text-white">
            {data
                ? <div className="text-xs leading-5 ">AudioPort - Version {data.version}
                    <div>{formatDate(new Date(Date.parse(data.buildTime)))}</div>
                </div>
                : <div className={"animate-pulse"}>
                    <div className="text-xs leading-5 w-full h-5 rounded ">Loading...</div>
                </div>
            }
            <div>
                <a href={"https://github.com/MaX-Lo/AudioPort"} target={"_blank"}>
                    <GitHubIcon className={"h-8 w-8 hover:text-gray-900 dark:hover:text-gray-400 hover:cursor-pointer"} />
                </a>
            </div>
        </div>
    </footer>
}
