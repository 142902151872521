import { isFinished } from "@/utils/audiobookUtils";
import React, { useState } from "react";
import { Audiobook, AudiobookUserInfo } from "@/api/generatedApi";
import useTranslation from "@/i18n";
import { inputCls } from "@/styles";
import { KeyValueStore } from "@/utils/kvStore";
import {CrossIcon} from "@/components/icons/CrossIcon";
import {SettingsIcon} from "@/components/icons/SettingsIcon";

export type AudiobookSearchOptions = {
    text: string;
    state: SearchState;
    interested: SearchInterested;
    language: SearchLanguage;
    audioAvailability: SearchAudioAvailability;
    downloaded: SearchDownloaded;
};

export enum SearchState {
    FINISHED,
    UNFINISHED,
    ANY,
}

export enum SearchInterested {
    IGNORED,
    INTERESTED,
    ANY,
}

export enum SearchLanguage {
    DE,
    EN,
    ES,
    ANY,
}

export enum SearchAudioAvailability {
    AVAILABLE,
    UNAVAILABLE,
    ANY,
}

export enum SearchDownloaded {
    DOWNLOADED,
    NOT_DOWNLOADED,
    ANY,
}

export function getTextCondition(text: string, a: Audiobook) {
    text = text.toLowerCase();
    return (
        a.name.toLowerCase().includes(text) ||
        a.authors
            .map((author) => author.name.toLowerCase())
            .join(" ")
            .includes(text) ||
        a.narrators
            .map((narrator) => narrator.name.toLowerCase())
            .join(" ")
            .includes(text)
    );
}

export function getStateCondition(state: SearchState, a: Audiobook, userInfo: AudiobookUserInfo | undefined): boolean {
    switch (state) {
        case SearchState.ANY:
            return true;
        case SearchState.FINISHED:
            return userInfo !== undefined && isFinished(a, userInfo);
        case SearchState.UNFINISHED:
            return userInfo === undefined || !isFinished(a, userInfo);
    }
}

export function getInterestedCondition(interested: SearchInterested, a: Audiobook, userInfo: AudiobookUserInfo | undefined): boolean {
    switch (interested) {
        case SearchInterested.INTERESTED:
            return userInfo === undefined || userInfo.isInterested;
        case SearchInterested.IGNORED:
            return userInfo !== undefined && !userInfo.isInterested;
        case SearchInterested.ANY:
            return true;
    }
}

export function getLanguageCondition(language: SearchLanguage, a: Audiobook) {
    switch (language) {
        case SearchLanguage.DE:
            return a.language === "german";
        case SearchLanguage.EN:
            return a.language === "english";
        case SearchLanguage.ES:
            return a.language === "spanish";
        case SearchLanguage.ANY:
            return true;
    }
}

export function getAudioAvailabilityCondition(availability: SearchAudioAvailability, a: Audiobook) {
    switch (availability) {
        case SearchAudioAvailability.AVAILABLE:
            return a.audioAvailable;
        case SearchAudioAvailability.UNAVAILABLE:
            return !a.audioAvailable;
        case SearchAudioAvailability.ANY:
            return true;
    }
}

const kvStore = new KeyValueStore("audiobooks-content");
export async function getDownloadedCondition(downloaded: SearchDownloaded, a: Audiobook) {
    switch (downloaded) {
        case SearchDownloaded.DOWNLOADED:
            return (await kvStore.get(a.id)) !== null;
        case SearchDownloaded.NOT_DOWNLOADED:
            return (await kvStore.get(a.id)) === null;
        case SearchDownloaded.ANY:
            return true;
    }
}

export function AudiobooksSearch({ searchOpt, setSearch }: { searchOpt: AudiobookSearchOptions; setSearch: (search: AudiobookSearchOptions) => void }) {
    const t = useTranslation();

    const [searchExpanded, expandSearch] = useState(false);

    const commonBtnCls = "border-gray-400 dark:border-slate-800 px-4 py-2 hover:bg-gray-300 dark:hover:bg-slate-800 transition";
    const searchDetails = (
        <div className="flex gap-2 flex-wrap justify-center mt-3">
            <div className="flex">
                <button
                    className={`rounded-l border ${commonBtnCls} ${
                        searchOpt.state === SearchState.FINISHED ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.state = SearchState.FINISHED;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchFinished")}
                </button>
                <button
                    className={`border-r border-t border-b border-gray-400 ${commonBtnCls} ${
                        searchOpt.state === SearchState.UNFINISHED ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.state = SearchState.UNFINISHED;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchNew")}
                </button>
                <button
                    className={`rounded-r border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.state === SearchState.ANY ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.state = SearchState.ANY;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchAny")}
                </button>
            </div>

            <div className="flex">
                <button
                    className={`rounded-l border ${commonBtnCls} ${
                        searchOpt.interested === SearchInterested.IGNORED ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.interested = SearchInterested.IGNORED;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchIgnore")}
                </button>
                <button
                    className={`border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.interested === SearchInterested.INTERESTED ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.interested = SearchInterested.INTERESTED;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchInterested")}
                </button>
                <button
                    className={`rounded-r border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.interested === SearchInterested.ANY ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.interested = SearchInterested.ANY;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchAny")}
                </button>
            </div>
            <div className="flex">
                <button
                    className={`rounded-l border ${commonBtnCls} ${
                        searchOpt.language === SearchLanguage.DE ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.language = SearchLanguage.DE;
                        setSearch(cpSearchOpt);
                    }}
                >
                    de
                </button>
                <button
                    className={`border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.language === SearchLanguage.EN ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.language = SearchLanguage.EN;
                        setSearch(cpSearchOpt);
                    }}
                >
                    en
                </button>
                <button
                    className={`border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.language === SearchLanguage.ES ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.language = SearchLanguage.ES;
                        setSearch(cpSearchOpt);
                    }}
                >
                    es
                </button>
                <button
                    className={`rounded-r border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.language === SearchLanguage.ANY ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.language = SearchLanguage.ANY;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchAny")}
                </button>
            </div>

            <div className="flex">
                <button
                    className={`rounded-l border ${commonBtnCls} ${
                        searchOpt.audioAvailability === SearchAudioAvailability.AVAILABLE ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.audioAvailability = SearchAudioAvailability.AVAILABLE;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchAudioAvailable")}
                </button>
                <button
                    className={`border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.audioAvailability === SearchAudioAvailability.UNAVAILABLE ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.audioAvailability = SearchAudioAvailability.UNAVAILABLE;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchAudioUnavailable")}
                </button>
                <button
                    className={`rounded-r border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.audioAvailability === SearchAudioAvailability.ANY ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.audioAvailability = SearchAudioAvailability.ANY;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("searchAny")}
                </button>
            </div>

            <div className="flex">
                <button
                    className={`rounded-l border ${commonBtnCls} ${
                        searchOpt.downloaded === SearchDownloaded.DOWNLOADED ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.downloaded = SearchDownloaded.DOWNLOADED;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("downloaded")}
                </button>
                <button
                    className={`border-r border-t border-b ${commonBtnCls} ${
                        searchOpt.downloaded === SearchDownloaded.NOT_DOWNLOADED ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.downloaded = SearchDownloaded.NOT_DOWNLOADED;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("not downloaded")}
                </button>
                <button
                    className={`border-r border-t border-b rounded-r ${commonBtnCls} ${
                        searchOpt.downloaded === SearchDownloaded.ANY ? "bg-gray-200 dark:bg-slate-500" : "bg-white dark:bg-slate-600"
                    }`}
                    onClick={() => {
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.downloaded = SearchDownloaded.ANY;
                        setSearch(cpSearchOpt);
                    }}
                >
                    {t("download any")}
                </button>
            </div>
        </div>
    );

    return (
        <>
            <div className="flex">
                <div className="block relative">
                    <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                            <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
                        </svg>
                    </span>
                    <input
                        value={searchOpt.text}
                        placeholder={t("search")}
                        className={`${inputCls} pl-8`}
                        onChange={(e) => {
                            e.preventDefault();
                            const cpSearchOpt = Object.assign({}, searchOpt);
                            cpSearchOpt.text = e.target.value;
                            setSearch(cpSearchOpt);
                        }}
                    />
                    <span className="h-full absolute inset-y-0 right-1 flex items-center pl-2 cursor-pointer"
                        onClick={() => {
                            const cpSearchOpt = Object.assign({}, searchOpt);
                            cpSearchOpt.text = "";
                            setSearch(cpSearchOpt);
                        }}>
                        <CrossIcon />
                    </span>

                </div>
                <button className="mx-3 px-2 rounded flex items-center hover:bg-slate-300 dark:hover:bg-slate-600 transition"
                        onClick={(event) => {
                            event.preventDefault();
                            expandSearch(!searchExpanded);
                        }}>
                   <SettingsIcon />
                </button>
            </div>
            {searchExpanded && searchDetails}
        </>
    );
}
