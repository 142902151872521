import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { formatDate, formatDuration } from "@/utils/util";
import { LoadingView } from "@/views/ErrorView";
import {
    Series,
    useLazyGetAudiobooksByIdQuery,
    useLazyGetAudiobooksByIdSeriesQuery,
    useLazyGetAudiobooksByIdUserInfoQuery,
    usePutAudiobooksByIdUserInfoMutation,
} from "@/api/generatedApi";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { isFinished } from "@/utils/audiobookUtils";
import { useImageHook } from "@/utils/imageHook";
import useTranslation, { TranslationKey } from "@/i18n";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { ButtonComponent } from "@/components/ui/ButtonComponent";
import { LinkComponent } from "@/components/ui/LinkComponent";
import { Card } from "@/components/Card";
import { QRCodeSVG } from "qrcode.react";
import { setNotification } from "@/store/notificationSlice";
import { Second } from "@/utils/constant";
import { AudioPlayer } from "@/views/audiobook/components/AudioPlayer";
import {
    AudiobookSearchOptions,
    SearchAudioAvailability,
    SearchDownloaded,
    SearchInterested,
    SearchLanguage,
    SearchState
} from "@/views/audiobook/components/AudiobookSearch";
import {useEphemeralGlobalState} from "@/utils/ephemeralState";

export async function getAudiobookDownloadUrl(sessionToken: string, audiobookId: string): Promise<string> {
    const fetchTokenUrl = `${import.meta.env.VITE_API_URL}audiobooks/${audiobookId}/download-token`;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${sessionToken}`);

    const response = await fetch(fetchTokenUrl, { headers });
    if (!response.ok) throw new Error("failed to retrieve download token");
    const token = await response.json();
    return `${import.meta.env.VITE_API_URL}download/${token["token"]}`;
}

function QrComponent() {
    const t = useTranslation();
    const dispatch = useDispatch();

    const currentUrl = window.location.href;
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(currentUrl);
            dispatch(
                setNotification({
                    title: t("link copied"),
                    text: t("the link to this audiobook has been copied to your clipboard."),
                    type: "info",
                    duration: 4 * Second,
                })
            );
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    return (
        <div className="bg-white rounded-lg p-1 cursor-pointer w-40 shadow-lg" onClick={copyToClipboard}>
            <QRCodeSVG value={currentUrl} size={152} />
        </div>
    );
}

export function AudiobookDetailsView() {
    const t = useTranslation();
    const loggedInUser = useSelector(selectUser)!;

    const { audiobookId } = useParams();

    const [fetchAudiobook, { data: audiobook, error: audiobookError }] = useLazyGetAudiobooksByIdQuery();
    const [fetchInfo, { data: userInfo }] = useLazyGetAudiobooksByIdUserInfoQuery();
    const [fetchSeries, { data: seriesList, error: seriesError }] = useLazyGetAudiobooksByIdSeriesQuery();

    const [downloadUrl, setDownloadUrl] = useState("");

    const [updateInfo, { data: updateData, error: updateError }] = usePutAudiobooksByIdUserInfoMutation();

    const [searchOpts, setSearchOpts] = useEphemeralGlobalState<AudiobookSearchOptions>("audiobooks-search");

    useEffect(() => {
        if (updateError) {
            console.log(`Failed to update ${JSON.stringify(updateError)}`);
            return;
        }
        if (updateData !== undefined && audiobookId !== undefined) {
            fetchInfo({ id: audiobookId });
        }
    }, [updateError, updateData]);

    // validate audiobookId and trigger fetching
    useEffect(() => {
        if (audiobookId === undefined) return;

        fetchAudiobook({ id: audiobookId });
        fetchInfo({ id: audiobookId });
        fetchSeries({ id: audiobookId });
        getAudiobookDownloadUrl(loggedInUser.sessionToken, audiobookId).then(setDownloadUrl);
    }, [audiobookId]);

    const imgSrc = useImageHook(audiobookId, 512);

    const err = audiobookError || seriesError;
    if (err) return <ErrorComponent error={err} />;
    if (!audiobook || !seriesList) return <LoadingView description={"loading"} />;

    const bookAppearsInSeries = function (series: Series) {
        return series.elements.some((element) => element.audiobookId === audiobook.id);
    };
    const seriesBookIsPartOf = seriesList.filter((series) => bookAppearsInSeries(series));
    const audibleLink = `https://www.audible.de/pd/${audiobook.audibleASIN}`;

    return (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-1 sm:gap-2 md:gap-3 lg:gap-4 max-w-7xl mx-auto p-2">
            <Card className="p-5 col-span-5 ">
                <h2 className="text-3xl leading-tight mb-5">{audiobook.name}</h2>
                <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start mb-4">
                    <img src={imgSrc} alt="Cover" className="mb-3 w-60 h-60" />

                    <div className="ml-7">
                        <div>
                            {seriesBookIsPartOf.map((series) => (
                                <Link
                                    to={`/series/${series.id}`}
                                    className="text-xs uppercase px-3 py-1 rounded-full bg-gray-200 text-gray-700 max-w-xs inline-block"
                                    key={series.id}
                                >
                                    <span className="font-bold mr-1">{series.elements.find((it) => it.audiobookId === audiobook.id)?.position}# </span>
                                    {series.name}
                                </Link>
                            ))}
                        </div>

                        <div className="mt-2 flex flex-wrap">
                            <div className="mr-4">
                                <label htmlFor="author" className="block text-xs">
                                    {t("audiobookAuthor")}
                                </label>
                                {audiobook.authors.map((author) => (
                                    <Link
                                        id="author"
                                        key={author.id}
                                        className="text-xs uppercase px-3 py-1 rounded-full bg-gray-200 text-gray-700 max-w-xs inline-block mr-1 my-1"
                                        onClick={() => setSearchOpts({
                                            text: author.name,
                                            state: SearchState.ANY,
                                            interested: SearchInterested.ANY,
                                            language: SearchLanguage.ANY,
                                            audioAvailability: SearchAudioAvailability.ANY,
                                            downloaded: SearchDownloaded.ANY,
                                        })}
                                        to={`/audiobooks?searchText="${author.name}"`}
                                    >
                                        {author.name}
                                    </Link>
                                ))}
                            </div>

                            <div className="mr-4">
                                <label htmlFor="narrator" className="block text-xs">
                                    {t("audiobookNarrator")}
                                </label>
                                {audiobook.narrators.map((narrator) => (
                                    <Link
                                        id="narrator"
                                        key={narrator.id}
                                        className="text-xs uppercase px-3 py-1 rounded-full bg-gray-200 text-gray-700 max-w-xs inline-block mr-1 my-1"
                                        onClick={() => setSearchOpts({
                                            text: narrator.name,
                                            state: SearchState.ANY,
                                            interested: SearchInterested.ANY,
                                            language: SearchLanguage.ANY,
                                            audioAvailability: SearchAudioAvailability.ANY,
                                            downloaded: SearchDownloaded.ANY,
                                        })}

                                        to={`/audiobooks?searchText="${narrator.name}"`}
                                    >
                                        {narrator.name}
                                    </Link>
                                ))}
                            </div>

                            <div className="mr-4">
                                <label htmlFor="language" className="block text-xs">
                                    {t("audiobookLanguage")}
                                </label>
                                <span id="language" className="block w-full">
                                    {t(audiobook.language as TranslationKey)}
                                </span>
                            </div>

                            <div className="mr-4">
                                <label htmlFor="duration" className="block text-xs">
                                    {t("audiobookLength")}
                                </label>
                                <span id="duration" className="block w-full">
                                    {formatDuration(audiobook.lengthMsec)}
                                </span>
                            </div>
                            <div className="mr-4">
                                <label htmlFor="publication" className="block text-xs">
                                    {t("audiobookPublicationDate")}
                                </label>
                                <span id="publication" className="block w-full">
                                    {formatDate(new Date(Date.parse(audiobook.publicationDate)), false)}
                                </span>
                            </div>
                            <div>
                                <label htmlFor="added-on" className="block text-xs">
                                    {t("audiobookAddedOn")}
                                </label>
                                <span id="added-on" className="block w-full">
                                    {formatDate(new Date(Date.parse(audiobook.timeCreated)), false)}
                                </span>
                            </div>
                        </div>

                        <div className="mt-2">
                            <label htmlFor="description" className="block text-xs">
                                {t("audiobookDescription")}
                            </label>
                            {audiobook.description}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    {downloadUrl && <LinkComponent text={t("audiobookDownloadBtn")} disabled={!audiobook.audioAvailable} href={downloadUrl} />}

                    <ButtonComponent
                        text={userInfo !== undefined && isFinished(audiobook, userInfo) ? t("audiobookMarkUnfinished") : t("audiobookMarkFinished")}
                        onClick={() => {
                            if (userInfo !== undefined && isFinished(audiobook, userInfo)) {
                                updateInfo({
                                    id: audiobook.id,
                                    updateAudiobookUserInfo: {
                                        progressMSec: 0,
                                        lastListenedTo: new Date().toISOString(),
                                    },
                                });
                            } else {
                                updateInfo({
                                    id: audiobook.id,
                                    updateAudiobookUserInfo: {
                                        progressMSec: audiobook.lengthMsec,
                                        lastListenedTo: new Date().toISOString(),
                                    },
                                });
                            }
                        }}
                    />

                    <ButtonComponent
                        text={userInfo === undefined || userInfo.isInterested ? t("audiobookMarkIgnore") : t("audiobookMarkInterested")}
                        onClick={() => {
                            updateInfo({
                                id: audiobook.id,
                                updateAudiobookUserInfo: {
                                    isInterested: userInfo !== undefined && !userInfo.isInterested,
                                },
                            });
                        }}
                    />

                    {audiobook.audibleASIN && <LinkComponent className="ml-2" text={t("audiobookOnAudible")} href={audibleLink} newTab={true} />}

                    {loggedInUser.isAdmin && <ButtonComponent text={<Link to={`/audiobooks/${audiobook.id}/edit`}>{t("edit")}</Link>} onClick={() => {}} />}
                </div>
            </Card>

            {audiobook.audioAvailable && (
                <Card className="p-6 col-span-5 lg:col-span-3">
                    <AudioPlayer audiobook={audiobook} />
                </Card>
            )}

            <div className="col-span-1">
                <QrComponent />
            </div>
        </div>
    );
}
