import {
    Audiobook,
    AudiobookUserInfo,
} from "@/api/generatedApi";
import React from "react";
import {useImageHook} from "@/utils/imageHook";

import {Card} from "@/components/Card";
import {Link} from "react-router-dom";
import {formatDuration, formatString} from "@/utils/util";

export function HistoryBookCard(props: { audiobook: Audiobook }) {
    const {audiobook} = props

    const imgSrc = useImageHook(audiobook.id, 128)

    const narratorsText = audiobook.narrators.map((narrator) => narrator.name).join(", ")

    return (
        <Card className={`my-3 ml-1  flex justify-items-start hover:ring-gray-400 hover:ring-2`}>
            <div className="shrink-0">
                <Link to={`/audiobooks/${audiobook.id}`}>
                    <img className="h-32 w-32 rounded-l" src={imgSrc} alt="Icon"/>
                </Link>
            </div>

            <div className="ml-5 py-2 min-w-0">
                <Link className="text-xl leading-tight hover:underline" to={`/audiobooks/${audiobook.id}`}>
                    {formatString(audiobook.name, 33)}
                </Link>
                <p className="truncate">{formatString(narratorsText, 33)}</p>
                <p className="truncate">{formatDuration(audiobook.lengthMsec)}</p>
            </div>
        </Card>
    )
}