import { useEffect, useState, useCallback, useMemo } from "react";

const stores = ["images", "player-state", "audiobooks-content"] as const;
export type Store = (typeof stores)[number];

let db: IDBDatabase;

export class KeyValueStore {
    private dbName: string = "AudioPortDB";
    private dbVersion: number = 10;
    private storeName: Store;

    constructor(storeName: Store) {
        this.storeName = storeName;
    }

    private openDatabase = async (): Promise<IDBDatabase> => {
        if (db) return db;

        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);

            request.onupgradeneeded = () => {
                const db = request.result;

                for (const store of stores) {
                    if (!db.objectStoreNames.contains(store)) {
                        db.createObjectStore(store);
                    }
                }
            };

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
        });
    };

    get = async <T>(key: string): Promise<T | null> => {
        try {
            const db = await this.openDatabase();
            const tx = db.transaction(this.storeName, "readonly");
            const store = tx.objectStore(this.storeName);

            return new Promise((resolve, reject) => {
                const request = store.get(key);
                request.onsuccess = () => {
                    if (request.result === undefined) {
                        resolve(null);
                        return;
                    }
                    resolve(request.result as T);
                };
                request.onerror = () => reject(request.error);
            });
        } catch (error) {
            console.error("Error getting value from KeyValueStore:", this.storeName, key, error);
            return null;
        }
    };

    set = async <T>(key: string, value: T): Promise<void> => {
        try {
            const db = await this.openDatabase();
            const tx = db.transaction(this.storeName, "readwrite");
            const store = tx.objectStore(this.storeName);

            return new Promise((resolve, reject) => {
                const request = store.put(value, key);
                request.onsuccess = () => resolve();
                request.onerror = () => reject(request.error);
            });
        } catch (error) {
            console.error("Error setting value in KeyValueStore:", this.storeName, key, error);
        }
    };

    pop = async <T>(key: string): Promise<T | null> => {
        const db = await this.openDatabase();
        const tx = db.transaction(this.storeName, "readwrite");
        const store = tx.objectStore(this.storeName);

        return new Promise((resolve, reject) => {
            const getRequest = store.get(key);

            getRequest.onsuccess = () => {
                const value = getRequest.result as T | null;

                if (value === undefined) {
                    resolve(null);
                    return;
                }

                const deleteRequest = store.delete(key);
                deleteRequest.onsuccess = () => resolve(value);
                deleteRequest.onerror = () => reject(deleteRequest.error);
            };

            getRequest.onerror = () => reject(getRequest.error);
        });
    };
}

export function useKeyValueStore<T>(storeName: Store, key: string) {
    if (!storeName) throw new Error("storeName is required");
    if (!key) throw new Error("key is required");

    const [value, setValue] = useState<T | null>(null);
    const store = useMemo(() => new KeyValueStore(storeName), [storeName]);

    // Load the initial value from IndexedDB
    useEffect(() => {
        const fetchValue = async () => {
            try {
                const storedValue = await store.get<T>(key);
                setValue(storedValue);
            } catch (error) {
                console.error("Error fetching value from KeyValueStore:", storeName, key, error);
            }
        };

        fetchValue();
    }, [key, store]);

    // Set a new value in IndexedDB and update the state
    const setStoredValue = useCallback(
        async (newValue: T) => {
            try {
                await store.set(key, newValue);
                setValue(newValue);
            } catch (error) {
                console.error("Error setting value in KeyValueStore:", storeName, key, error);
            }
        },
        [key, store]
    );

    const deleteStoredValue = useCallback(async () => {
        try {
            await store.pop<T>(key);
            setValue(null);
        } catch (error) {
            console.error("Error deleting value in KeyValueStore:", storeName, key, error);
        }
    }, [key, store]);

    return [value, setStoredValue, deleteStoredValue] as const;
}
