export function formatDuration(ms: number, format?: string): string {
    const milliseconds = ms % 1000;
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));

    if (format) {
        // Replace placeholders with corresponding values
        const formatted = format
            .replace("DD", String(days).padStart(2, "0"))
            .replace("HH", String(hours).padStart(2, "0"))
            .replace("MM", String(minutes).padStart(2, "0"))
            .replace("SS", String(seconds).padStart(2, "0"))
            .replace("ms", String(milliseconds).padStart(3, "0"));
        return formatted;
    }

    // Default format if no format string is provided
    const dayStr = days > 0 ? `${days}d ` : "";
    const hourStr = hours > 0 ? `${hours}h ` : "";
    const minuteStr = minutes > 0 ? `${minutes}min ` : "";
    const secondStr = seconds > 0 ? `${seconds}sec ` : "";
    const millisecondStr = `${milliseconds > 0 ? milliseconds : 0}ms`;

    return `${dayStr}${hourStr}${minuteStr}${secondStr}${millisecondStr}`;
}

export function formatDataSize(bytes: number): string {
    if (bytes < 1e6) {
        return "0 MB"; // Less than 1 MB is rounded to 0
    }

    const units = ["MB", "GB", "TB"];
    let size = bytes / 1e6; // Start with MB
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
}

export function formatDecimals(num: number, decimals: number = 2): number {
    const log10 = num ? Math.floor(Math.log10(num)) : 0,
        div = log10 < 0 ? Math.pow(10, 1 - log10) : 10**decimals;

    return Math.round(num * div) / div;
}